import {Component, Input} from '@angular/core';

import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-fact-card-item',
  templateUrl: './fact-card-item.component.html',
  styleUrls: ['./fact-card-item.component.css']
})

export class FactCardItemComponent {

  @Input() sourceList: [];
  @Input() favouriteList: [];

  constructor() {
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(event.previousContainer.data, event.container.data,
        event.previousIndex, event.currentIndex);
    } else {
      moveItemInArray(this.sourceList, event.previousIndex, event.currentIndex);
    }

  }

}
