import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Actions } from '../services/actions.service';
import { FactsEpics } from './facts.epics';
import { RapidApiService } from '../services/rapid-api.service';
import { FactCardListComponent } from './fact-card-list/fact-card-list.component';
import { FactCardItemComponent } from './fact-card-item/fact-card-item.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    DragDropModule
  ],
  providers: [
    Actions,
    RapidApiService,
    FactsEpics,
  ],
  declarations: [
    FactCardListComponent,
    FactCardItemComponent
  ]
})
export class FactsModule {}
