import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Category, Fact} from '../models';

const headers = new HttpHeaders().set('x-rapidapi-key', '53a61af534mshea943f753441ac9p1a9f6ejsnd6468530352c');

@Injectable()
export class RapidApiService {
  private API_BASE_URL = 'https://matchilling-chuck-norris-jokes-v1.p.rapidapi.com/';
  private CATEGORIES_ENDPOINT = 'jokes/categories';
  private JOKE_ENDPOINT = 'jokes/random';

  constructor(private httpClient: HttpClient) {
  }

  fetchData = () => this.httpClient.get<Category>(this.API_BASE_URL + this.CATEGORIES_ENDPOINT, {headers});
  fetchFacts = (category) => this.httpClient.get<Fact>(this.API_BASE_URL +
    this.JOKE_ENDPOINT + '?category=' + category, {headers})
}
