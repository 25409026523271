import {Component, Input, OnInit} from '@angular/core';
import {select, WithSubStore} from '@angular-redux/store';
import {Observable} from 'rxjs';

import {factsReducer} from '../facts.reducer';
import {Actions} from '../../services/actions.service';

@WithSubStore({
  basePathMethodName: 'getReduxBasePath',
  localReducer: factsReducer
})
@Component({
  selector: 'app-root',
  templateUrl: './fact-card-list.component.html',
  styleUrls: ['./fact-card-list.component.css']
})

export class FactCardListComponent implements OnInit {

  @select() public readonly categories$: Observable<[]>;
  @select() public readonly facts$: Observable<[]>;
  @select() public readonly updateCount$: Observable<number>;

  @select() public readonly fetchStatus$: Observable<string>;
  @select() public readonly fetchedData$: Observable<string>;

  @Input() sourceList = [];
  @Input() favouriteList = [];

  @Input() loading: boolean;

  constructor(
    protected factsActions: Actions
  ) {
    this.categories$.subscribe(data => {
      this.loading = false;
    });

    this.facts$.subscribe((data: any) => {
      if (data.value) {
        this.sourceList.push(data.value);
        this.sourceList.sort();
        this.loading = false;
      }
    });
  }

  ngOnInit() {
    this.fetchCategories();
  }

  onCategorySelect($event) {
    this.fetchFacts($event.value);
  }

  getReduxBasePath() {
    return ['facts'];
  }

  fetchCategories() {
    this.loading = true;
    this.factsActions.fetchCategories();
  }

  fetchFacts(category) {
    this.loading = true;
    this.factsActions.fetchFacts(category);
  }

}
