import {NgModule} from '@angular/core';

import {
  DevToolsExtension,
  NgRedux,
  NgReduxModule,
} from '@angular-redux/store';

// Redux ecosystem stuff.
import {createLogger} from 'redux-logger';

// The top-level reducers and epics that make up our app's logic.
import {RootEpics} from './store.epics';
import {AppState} from './store.model';
import {rootReducer} from './store.reducers';
import {createEpicMiddleware, combineEpics} from 'redux-observable';
import {AnyAction} from 'redux';

@NgModule({
  imports: [NgReduxModule],
  providers: [RootEpics],
})
export class StoreModule {
  constructor(
    public store: NgRedux<AppState>,
    devTools: DevToolsExtension,
    rootEpics: RootEpics,
  ) {

    const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState>();
    // Tell Redux about our reducers and epics. If the Redux DevTools
    // chrome extension is available in the browser, tell Redux about
    // it too.
    store.configureStore(
      rootReducer,
      {},
      [epicMiddleware],
      devTools.isEnabled() ? [devTools.enhancer()] : [],
    );

    epicMiddleware.run(combineEpics(...rootEpics.getEpics()));
  }
}
