import { Injectable } from '@angular/core';

import { FactsEpics } from '../components/facts.epics';

@Injectable()
export class RootEpics {
  constructor(
    private factsEpics: FactsEpics
    ) {}

  getEpics() {
    return [
      ...this.factsEpics.getCategories(),
      ...this.factsEpics.getFacts(),
    ];
  }
}
