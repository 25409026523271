import {Injectable} from '@angular/core';
import {Epic, ofType} from 'redux-observable';
import {switchMap, catchError, startWith, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {AnyAction} from 'redux';

import {AppState} from '../store/store.model';
import {Actions, Action} from '../services/actions.service';
import {RapidApiService} from '../services/rapid-api.service';


@Injectable()
export class FactsEpics {
  constructor(
    private service: RapidApiService
  ) {
  }

  getCategories(): Epic<AnyAction, AnyAction, AppState>[] {
    return [this.fetchAsyncDataCategories];
  }

  getFacts(): Epic<AnyAction, AnyAction, AppState>[] {
    return [this.fetchAsyncDataFact];
  }

  private fetchAsyncDataCategories: Epic<Action, Action, AppState> = (action$, store$) =>
    action$.pipe(
      ofType(Actions.FETCH_CATEGORIES),
      switchMap(action =>
        this.service.fetchData().pipe(
          map(response => ({
            type: Actions.FETCH_CATEGORIES_SUCCESS,
            meta: {},
            payload: {
              response
            }
          } as Action)),
          catchError(err => of({
            type: Actions.FETCH_CATEGORIES_FAIL,
            meta: {},
            payload: {
              error: true
            }
          } as Action)),
          startWith({
            type: Actions.FETCH_CATEGORIES_START
          } as Action)
        )
      )
    )

  private fetchAsyncDataFact: Epic<Action, Action, AppState> = (action$, store$) =>
    action$.pipe(
      ofType(Actions.FETCH_FACTS),
      switchMap(action =>
        this.service.fetchFacts(action.payload).pipe(
          map(response => ({
            type: Actions.FETCH_FACTS_SUCCESS,
            meta: {},
            payload: {
              response
            }
          } as Action)),
          catchError(err => of({
            type: Actions.FETCH_FACTS_FAIL,
            meta: {},
            payload: {
              error: true
            }
          } as Action)),
          startWith({
            type: Actions.FETCH_FACTS_START
          } as Action)
        )
      )
    )
}
