import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
import { dispatch } from '@angular-redux/store';

interface Payload {
  response?: any;
}

export type Action = FluxStandardAction<Payload, {}>;

@Injectable()
export class Actions {
  static readonly FETCH_CATEGORIES = 'FETCH_CATEGORIES';
  static readonly FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
  static readonly FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL';
  static readonly FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
  static readonly FETCH_FACTS = 'FETCH_FACTS';
  static readonly FETCH_FACTS_START = 'FETCH_FACTS_START';
  static readonly FETCH_FACTS_FAIL = 'FETCH_FACTS_FAIL';
  static readonly FETCH_FACTS_SUCCESS = 'FETCH_FACTS_SUCCESS';

  @dispatch()
  fetchCategories = (): Action => ({
    type: Actions.FETCH_CATEGORIES,
    meta: {},
    payload: {}
  })

  @dispatch()
  fetchFacts = (category): Action => ({
    type: Actions.FETCH_FACTS,
    meta: {},
    payload: category
  })
}
