import { Action, Actions } from '../services/actions.service';
import { Facts } from '../models/facts';

const INITIAL_STATE: Facts = {
  fetchStatus: 'Ready',
  categories: [''],
  facts: {}
};

export function factsReducer(
  state: Facts = INITIAL_STATE,
  action: Action
): Facts {

  switch (action.type) {

    case Actions.FETCH_CATEGORIES_START:
      return {
        ...state,
        fetchStatus: 'Fetching...',
      };

    case Actions.FETCH_CATEGORIES_FAIL:
      return {
        ...state,
        fetchStatus: 'Error when fetching data!',
      };

    case Actions.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetchStatus: 'Done fetching!',
        categories: action.payload.response,
      };

    case Actions.FETCH_FACTS_START:
      return {
        ...state,
        fetchStatus: 'Fetching...',
      };

    case Actions.FETCH_FACTS_FAIL:
      return {
        ...state,
        fetchStatus: 'Error when fetching data!',
      };

    case Actions.FETCH_FACTS_SUCCESS:
      return {
        ...state,
        fetchStatus: 'Done fetching!',
        facts: action.payload.response,
      };
  }

  return state;
}
